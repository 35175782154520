<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>OnSite</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">
                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <on-site-navigation :links="slotProps.links"></on-site-navigation>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <div class="p-2 h-full">
                                        <div class="grid grid-cols-2 gap-2">
                                            <div v-if="response.body.actions.filter(action => action.name === 'export-report').first()">
                                                <h2>Export Weekly Report</h2>
                                                <p class="my-2 text-sm italic">Fields exported: employee, accounting code, tax rate, hours and billable total</p>
                                                <div class="flex justify-center">
                                                    <form-action v-if="response.body.actions.filter(action => action.name === 'export-report').first()"
                                                                 :action="response.body.actions.filter(action => action.name === 'export-report').first()"
                                                                 action-key="export-report"></form-action>
                                                </div>
                                            </div>
                                            <div v-if="response.body.actions.filter(action => action.name === 'export-accounting-report').first()">
                                                <h2>Export Accounting Weekly Report</h2>
                                                <p class="my-2 text-sm italic">Fields exported: type, nominal code, tax rate, tax amount, description, employee, accounting code, department, department code, total (h), net, gross and payment date</p>
                                                <div class="flex justify-center">
                                                    <form-action v-if="response.body.actions.filter(action => action.name === 'export-accounting-report').first()"
                                                                 :action="response.body.actions.filter(action => action.name === 'export-accounting-report').first()"
                                                                 action-key="export-report"></form-action>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import OnSiteNavigation from "@/v3/components/navigation/OsNavigation.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";

export default {
    components: {
        FormAction,
        OnSiteNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,
    },
    data() {
        return {
            slotProps: null,
            users: null,
            date: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=on_site_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=on_site_light"
        }
    }
}
</script>

